// src/components/LandingPage/LandingPage.js
import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';


// Replace these with actual image imports or URLs in your project
import heroImage from '../../Assets/hero-image.jpg';
import agriServiceImage from '../../Assets/crop-monitoring.jpg';
import surveillanceImage from '../../Assets/area-monitoring.jpg';
import pipelineInspectionImage from '../../Assets/pipeline-inspection.jpg';
import droneDestinationLogo from '../../Assets/drone-destination-logo.png'; // Add actual logos if available
import avplLogo from '../../Assets/avpl-logo.png';
import spectronLogo from '../../Assets/spectron-logo.png';
import triveniLogo from '../../Assets/triveni-logo.png';
import Reviews from '../Reviews/Reviews';

const LandingPage = () => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: '#f9f9f9' }}>
      {/* Hero Section */}
      <Box
        sx={{
          py: [8, 15],
          textAlign: 'center',
          color: '#fff',
          backgroundImage: `url(${heroImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          
        }}
      >
        <Container>
          <Fade top>
            <Typography variant="h2" gutterBottom>
              Welcome to Dsicon Enterprises Pvt Ltd
            </Typography>
            <Typography variant="h6" paragraph>
              Innovating the Future with Drone Technology Solutions for Agriculture and Surveillance
            </Typography>
          </Fade>
        </Container>
      </Box>

      {/* Services Section */}
      <Box sx={{ py: 6 }}>
        <Container>
          <Typography variant="h3" align="center" color="primary" gutterBottom>
            Our Services
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {/* Agricultural Drone Services Card */}
            <Grid item xs={12} md={4}>
              <Zoom>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    boxShadow: 3,
                    borderRadius: 2,
                  }}
                >
                  <CardMedia
                    component="img"
                    image={agriServiceImage}
                    alt="Agricultural Drone Services"
                    sx={{ height: 250 }}
                  />
                  <CardContent sx={{ textAlign: 'center', flexGrow: 1 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                      Agricultural Drone Services
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                      Offering precision farming solutions, including crop monitoring, soil analysis, and precision spraying.
                    </Typography>
                  </CardContent>
                  <Box sx={{ textAlign: 'center', pb: 2 }}>
                    <Button component={Link} to="/agri-services" variant="contained" color="primary">
                      Learn More
                    </Button>
                  </Box>
                </Card>
              </Zoom>
            </Grid>

            {/* Drone Surveillance Card */}
            <Grid item xs={12} md={4}>
              <Zoom>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    boxShadow: 3,
                    borderRadius: 2,
                  }}
                >
                  <CardMedia
                    component="img"
                    image={surveillanceImage}
                    alt="Drone Surveillance"
                    sx={{ height: 250 }}
                  />
                  <CardContent sx={{ textAlign: 'center', flexGrow: 1 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                      Drone Surveillance
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                      Advanced surveillance solutions for security, wildlife tracking, and infrastructure inspection.
                    </Typography>
                  </CardContent>
                  <Box sx={{ textAlign: 'center', pb: 2 }}>
                    <Button component={Link} to="/surveillance" variant="contained" color="primary">
                      Learn More
                    </Button>
                  </Box>
                </Card>
              </Zoom>
            </Grid>

            {/* Pipeline Inspection Card */}
            <Grid item xs={12} md={4}>
              <Zoom>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    boxShadow: 3,
                    borderRadius: 2,
                  }}
                >
                  <CardMedia
                    component="img"
                    image={pipelineInspectionImage}
                    alt="Pipeline Inspection"
                    sx={{ height: 250 }}
                  />
                  <CardContent sx={{ textAlign: 'center', flexGrow: 1 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                      Pipeline Inspection
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                      High-resolution drone inspections to monitor and maintain the integrity of pipelines, detecting leaks and damages efficiently.
                    </Typography>
                  </CardContent>
                  <Box sx={{ textAlign: 'center', pb: 2 }}>
                    <Button component={Link} to="/pipeline-inspection" variant="contained" color="primary">
                      Learn More
                    </Button>
                  </Box>
                </Card>
              </Zoom>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Box sx={{ py: 6, backgroundColor: '#E0E7FF' }}>
        <Container>
          <Fade bottom>
            <Typography variant="h3" align="center" color="primary" gutterBottom>
              Why Choose Us
            </Typography>
            <Grid container spacing={4} sx={{ mt: 4 }}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="secondary" gutterBottom>
                  Advanced Technology
                </Typography>
                <Typography variant="body2">
                  We utilize cutting-edge drone technology to deliver accurate and efficient solutions for various industries.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="secondary" gutterBottom>
                  Experienced Team
                </Typography>
                <Typography variant="body2">
                  Our team consists of experts in drone technology and application, ensuring quality service delivery.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="secondary" gutterBottom>
                  Customer Satisfaction
                </Typography>
                <Typography variant="body2">
                  We prioritize client needs, delivering customized solutions to meet specific business requirements.
                </Typography>
              </Grid>
            </Grid>
          </Fade>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ py: 6 }}>
        <Container>
          <Fade left>
            <Typography variant="h3" align="center" color="primary" gutterBottom>
              What Our Clients Say
            </Typography>
          </Fade>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={6}>
              <Fade left>
                <Card>
                  <CardContent>
                    <Typography variant="body1" color="text.secondary">
                      "Dsicon Enterprises has transformed our agricultural processes with their precision farming solutions. Highly recommend!"
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="right">
                      - Happy Farmer
                    </Typography>
                  </CardContent>
                </Card>
              </Fade>
            </Grid>
            <Grid item xs={12} md={6}>
              <Fade right>
                <Card>
                  <CardContent>
                    <Typography variant="body1" color="text.secondary">
                      "The drone surveillance services are top-notch and have improved our security management significantly."
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="right">
                      - Security Manager
                    </Typography>
                  </CardContent>
                </Card>
              </Fade>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ marginLeft: "10px",marginRight:"10px"  }}>
        <Reviews/>
      </Box>

      {/* Our Patrons & Partners Section */}
      <Typography variant="h3" align="center" color="primary" gutterBottom>
        Our Patrons & Partners
      </Typography>
      <Box sx={{ py: 6, backgroundColor: theme.palette.primary.main }}>
        <Container>
          <Fade bottom>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                            {/* Partner 4 - Triveni Engineering and Industries Ltd */}
                            <Grid
                item
                xs={6}
                sm={4}
                md={2}
                display="flex"
                justifyContent="center"
                sx={{ margin: { xs: 1, sm: 2 } }} // Add margin for spacing
              >
                <Box
                  sx={{
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={triveniLogo}
                    alt="Triveni Engineering and Industries Ltd"
                    style={{
                      maxWidth: '300px',
                    }}
                  />
                </Box>
              </Grid>
              
              {/* Partner 1 - Drone Destination */}
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                display="flex"
                justifyContent="center"
                sx={{ margin: { xs: 1, sm: 2 } }} // Add margin for spacing
              >
                <Box
                  sx={{
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={droneDestinationLogo}
                    alt="Drone Destination"
                    style={{
                      maxWidth: '200px',
                    }}
                  />
                </Box>
              </Grid>

              {/* Partner 2 - AVPL International */}
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                display="flex"
                justifyContent="center"
                sx={{ margin: { xs: 1, sm: 2 } }} // Add margin for spacing
              >
                <Box
                  sx={{
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={avplLogo}
                    alt="AVPL International"
                    style={{
                      maxWidth: '200px',
                    }}
                  />
                </Box>
              </Grid>

              {/* Partner 3 - Spectron Robotics */}
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                display="flex"
                justifyContent="center"
                sx={{ margin: { xs: 1, sm: 2 } }} // Add margin for spacing
              >
                <Box
                  sx={{
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={spectronLogo}
                    alt="Spectron Robotics"
                    style={{
                      maxWidth: '250px',
                    }}
                  />
                </Box>
              </Grid>


            </Grid>
          </Fade>
        </Container>
      </Box>




      {/* Call to Action Section */}
      <Box sx={{ py: 8, textAlign: 'center' }}>
        <Container>
          <Fade bottom>
            <Typography variant="h4" gutterBottom>
              Ready to Enhance Your Operations?
            </Typography>
            <Typography variant="body1" paragraph>
              Contact us today to discover how our drone solutions can transform your business.
            </Typography>
            <Button variant="contained" color="primary" size="large" href="/contact">
              Get in Touch
            </Button>
          </Fade>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;

import React, { useState } from 'react';
import { Box, Typography, Grid, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const reviews = [
  {
    name: 'Priya Desai',
    position: 'Head of Security, Metro Industries',
    feedback: 'The advanced surveillance and real-time tracking have helped us secure our premises more effectively than ever before.',
  },
  {
    name: 'Debasish Roy',
    position: 'Additional Commissioner, Kolkata Police',
    feedback: 'The plan to buy another drone was mooted long ago. But its service during the Chatterjee International fire has accelerated the process.',
  },
  {
    name: 'Amit Sharma',
    position: 'Operations Manager, AGP Corp',
    feedback: 'Using drones for crop monitoring has made a tremendous difference in yield quality and monitoring efficiency.',
  },
  {
    name: 'Neha Gupta',
    position: 'CEO, Green Earth Farms',
    feedback: 'Precision farming with drones has revolutionized our approach to sustainable agriculture and enhanced crop productivity.',
  },
  {
    name: 'Rahul Verma',
    position: 'Director, Safe Cities Initiative',
    feedback: 'Drone technology has significantly contributed to our efforts in urban security and real-time threat detection.',
  },
];

const CustomerReviews = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile view
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? reviews.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === reviews.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Box sx={{ py: 6, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h3" align="center" gutterBottom>
        Customer Reviews
      </Typography>

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: isMobile ? '100%' : '1200px',
          mx: 'auto',
          px: 2,
        }}
      >
        {/* Left Arrow */}
        {isMobile && (
          <IconButton
            onClick={handlePrev}
            sx={{
              position: 'absolute',
              left: 0,

              color: theme.palette.primary.main,
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        )}

        <Grid container spacing={3} justifyContent="center">
          {isMobile
            ? reviews.slice(currentIndex, currentIndex + 1).map((review, index) => (
                <Grid item xs={12} key={index}>
                  <Box
                    sx={{
                      p: 3,
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.text.primary,
                      borderRadius: 2,
                      margin: "25px",
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                      “{review.feedback}”
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 2, color: theme.palette.secondary.main }}>
                      {review.name}
                    </Typography>
                    <Typography variant="body2">{review.position}</Typography>
                  </Box>
                </Grid>
              ))
            : reviews.map((review, index) => (
                <Grid item xs={12} sm={4} md={4} key={index}>
                  <Box
                    sx={{
                      p: 3,
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.text.primary,
                      borderRadius: 2,
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                      “{review.feedback}”
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 2, color: theme.palette.secondary.main }}>
                      {review.name}
                    </Typography>
                    <Typography variant="body2">{review.position}</Typography>
                  </Box>
                </Grid>
              ))}
        </Grid>

        {/* Right Arrow */}
        {isMobile && (
          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              right: 0,
              color: theme.palette.primary.main,
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default CustomerReviews;

// src/components/AgriServices/AgriServices.js
import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';

// Replace with actual image imports or URLs
import cropMonitoringImage from '../../Assets/crop-monitoring.jpg';
import soilAnalysisImage from '../../Assets/soil-analysis.jpg';
import precisionSprayingImage from '../../Assets/precision-spraying.jpg';
import fieldMappingImage from '../../Assets/field-mapping.jpg';
import irrigationManagementImage from '../../Assets/irrigation-management.jpg';
import yieldEstimationImage from '../../Assets/yield-estimation.jpg';

const AgriServices = () => {
  return (
    <Box sx={{ py: 6, backgroundColor: '#f9f9f9' }}>
      <Container>
        {/* Header Section */}
        <Typography variant="h3" gutterBottom align="center" color="primary">
          Agricultural Drone Services
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          Revolutionizing agriculture with precision drone technology across multiple services.
        </Typography>

        {/* Service Details Section */}
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Crop Monitoring and Health Assessment */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={cropMonitoringImage}
                alt="Crop Monitoring and Health Assessment"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Crop Monitoring and Health Assessment
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Our drones capture multispectral images to assess crop health, identify stress areas, and detect diseases early for timely intervention.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Soil Analysis */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={soilAnalysisImage}
                alt="Soil Analysis"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Soil Analysis
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Conduct aerial surveys to evaluate soil conditions, moisture levels, and nutrient content, assisting in efficient land and crop management.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Precision Spraying */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={precisionSprayingImage}
                alt="Precision Spraying"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Precision Spraying
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Targeted application of fertilizers and pesticides to minimize waste, maximize crop quality, and reduce environmental impact.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Field Mapping and Surveying */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={fieldMappingImage}
                alt="Field Mapping and Surveying"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Field Mapping and Surveying
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Create detailed, high-resolution maps of fields to assist in planning, planting, and monitoring, providing valuable insights for land management.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Irrigation Management */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={irrigationManagementImage}
                alt="Irrigation Management"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Irrigation Management
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Monitor water distribution and identify areas requiring irrigation adjustments to ensure optimal water usage across the farm.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Yield Estimation */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={yieldEstimationImage}
                alt="Yield Estimation"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Yield Estimation
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Use drone data to estimate crop yields, providing insights for future planting and market strategies, enhancing productivity and profits.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Call to Action Section */}
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Transform Your Farming Practices
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Contact us today to learn more about our agricultural drone services and how we can assist you in achieving higher efficiency and productivity.
          </Typography>
          <Button variant="contained" color="primary" size="large" href="/contact">
            Get in Touch
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default AgriServices;

// src/components/Footer/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Typography, Grid, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  const theme = useTheme(); // Access the theme

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
        py: 4,
        px: 3,
        textAlign: "center",
        borderRadius: 2, // Optional: for rounded corners
        
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Quick Links Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom color={theme.palette.secondary.main}>
              Quick Links
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, }}>
              <Link to="/" style={{ color: theme.palette.text.primary, textDecoration: 'none' }}><Typography>Home</Typography></Link>
              <Link to="/agri-services" style={{ color: theme.palette.text.primary, textDecoration: 'none' }}><Typography>Agricultural Services</Typography></Link>
              <Link to="/surveillance" style={{ color: theme.palette.text.primary, textDecoration: 'none' }}><Typography>Surveillance</Typography></Link>
              <Link to="/about" style={{ color: theme.palette.text.primary, textDecoration: 'none' }}><Typography>About Us</Typography></Link>
              <Link to="/contact" style={{ color: theme.palette.text.primary, textDecoration: 'none' }}><Typography>Contact</Typography></Link>
            </Box>
          </Grid>

          {/* Contact Information Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom color={theme.palette.secondary.main}>
              Contact Us
            </Typography>
            <Typography variant="body2">Vill: Kuwarpur, Tehshel: Shikarpur, District: Bulandshahr, UP, 202396</Typography>
            <Typography variant="body2">Phone: +91 8000606017</Typography>
            <Typography variant="body2">Email: business@dsiconenterprises.com</Typography>
          </Grid>

          {/* Social Media Section */}

          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom color={theme.palette.secondary.main} align="center">
              Follow Us
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
              <IconButton href="https://facebook.com" target="_blank" sx={{ color: theme.palette.secondary.main }}>
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://twitter.com" target="_blank" sx={{ color: theme.palette.secondary.main }}>
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://linkedin.com" target="_blank" sx={{ color: theme.palette.secondary.main }}>
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        {/* Bottom Section */}
        <Box sx={{ textAlign: 'center', pt: 4, color: 'white' }}>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Dsicon Enterprises PVT. LTD. . All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0D1B2A', // Navy Blue
    },
    secondary: {
      main: '#39A0ED', // Turquoise
    },
    background: {
      default: '#C4C4C4', // Silver Gray
    },
    text: {
      primary: '#FFFFFF', // White for text on dark backgrounds
      secondary: '#0D1B2A', // Navy Blue for text on light backgrounds
    },
    action: {
      active: '#3FFFAB', // Electric Green for interactive elements like CTA buttons
    },
  },
  typography: {
    fontFamily: 'Cormorant Garamond',
  },
});

export default theme;
    
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css"
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import LandingPage from './components/Home/LandingPage';
import AgriServices from './components/AgriServices/AgriServices';
import Surveillance from './components/Surveillance/Surveillance';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import ScrollToTop from './components/ScrollToTop';

const App = () => (
  <Router>
    <div className="app">
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/agri-services" element={<AgriServices />} />
        <Route path="/surveillance" element={<Surveillance />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  </Router>
);

export default App;

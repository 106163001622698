// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import your theme
import { SpeedInsights } from '@vercel/speed-insights/react';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
    <SpeedInsights />
  </ThemeProvider>,
  document.getElementById('root')
);
